<template>
  <div id="kt_app_content" class="app-content flex-column-fluid">
    <!--begin::Content container-->
    <div id="kt_app_content_container" class="app-container container-xxl">
      <!--begin::Contact-->
      <div class="card p-0">
        <!--begin::Body-->
        <div class="card-body d-flex flex-column p-lg-17">
          <!--begin::Row-->
          <div class="d-flex w-100 justify-content-center mb-10">
            <h1>Member’s Terms of Use</h1>
          </div>
          <div class="d-flex w-100 flex-column">
            <h5 class="font-weight-bold mb-3">1. APPLICATION OF TERMS</h5>
            <p>
              These terms apply to any person or entity (‘you/your’) accessing
              (‘our/the website’). This website is owned and operated by Tenant
              Hub Pty Ltd ACN 29 660 508 369 (‘we/our/us’). By accessing and
              using our website you agree to be bound by these terms. We may
              amend these terms and conditions from time to time. These terms
              are governed by the laws of Queensland.
            </p>

            <h5>2. COLLECTION AND USE OF PERSONAL INFORMATION</h5>
            <p>
              You will need to register to use our website. When you register
              you will provide us with personal information such as your name,
              entity’s name, and email address. We will handle all personal
              information we collect in accordance with our privacy policy,
              available at
              <router-link to="/privacy-policy">Privacy Policy</router-link> and
              the applicable law.
            </p>

            <p>
              When you register your account, we will provide you with a
              username and password. You are responsible for keeping this
              username and password secure and are responsible for all use and
              activity carried out under this username. You agree and understand
              that you are responsible for maintaining the confidentiality of
              your username and password associated with your account. You will
              be solely responsible for all activities occur under your account.
            </p>

            <p>
              We may otherwise collect further information about you and your
              entity to provide our members and for other purposes as detailed
              in our privacy policy and as required or permitted under
              applicable law. This may include disclosing your information to
              third parties that help us deliver our services, such as
              information technology suppliers, communication suppliers, finance
              providers and our business partners.
            </p>
            <p>
              We will handle all personal information we collect in accordance
              with our privacy policy and the applicable law. Our privacy policy
              explains:
            </p>

            <p>
              (a) how we store and use, and how you may access and correct your
              personal information; (b) how you can lodge a complaint regarding
              the handling of your personal information; and (c) how we will
              handle any complaint.
            </p>

            <p>
              If you would like any further information about our privacy
              policies or practices, please contact us.
            </p>

            <p>
              By providing your personal information to us, you consent to the
              collection, use, storage and disclosure of that information as
              described in the privacy policy and these terms.
            </p>

            <h5>3. WEBSITE AND SERVICES</h5>
            <p>
              Our website provides our members with access to our database which
              allows members to share tenancy information. Our website allows
              its members to lodge information onto the database and to enquire
              on information that may be listed on our database. As a member of
              the website you will agree that the database and any information
              contained in such database will only be used for the purposes for
              which such information was provided.
            </p>

            <p>
              All services are subject to availability. We will inform you as
              soon as possible if the services you have ordered are not
              available.
            </p>

            <h5>4. MEMBERSHIP</h5>
            <p>
              We will not issue any refund in relation to the membership. This
              policy does not exclude a person’s right under Australian consumer
              law.
            </p>

            <p>
              Registration to the website is on an individual basis. Should an
              individual use multiple names they will require multiple
              subscriptions.
            </p>

            <p>
              The individual intending to have access to the whole database
              shall pay us a monthly subscription. Non-paying membership will
              only have restricted access to the database.
            </p>

            <p>
              The member must provide at least two identifying features on an
              individual being a minimum of name and date of birth.
            </p>

            <h5>5. CONDUCT</h5>
            <p>
              When you use our website, we expect you to abide by a certain
              standard of behaviour. You must not do or attempt to do anything
              that is unlawful, which is prohibited by any laws applicable to
              our website, which we would consider inappropriate, or which might
              bring us or our website into disrepute. This includes:-
            </p>

            <ul>
              <li>
                Anything that would constitute a breach of an individual’s
                privacy (including up[loading private or personal information
                without an individual’s consent) or any other legal rights;
              </li>
              <li>
                Using our website to defame, harass, threaten, menace or offend
                any person;
              </li>
              <li>Interfering with any user of our website;</li>
              <li>
                Tampering with or modifying our website, knowingly transmitting
                viruses or other disabling features, or damaging or interfering
                with our Website, including (without limiting) using trojan
                horses, viruses or privacy or programming routines that may
                damage or interfere with our Website;
              </li>
              <li>
                Using our website to send unsolicited electronic messages;
              </li>
              <li>
                Using our website in a way which competes with our core
                business;
              </li>
              <li>
                Facilitating or assisting a third party to do any of the above;
              </li>
              <li>
                You are able to substantiate the information recorded about an
                individual on the database;
              </li>
              <li>
                The information provided to the database is accurate, complete
                and up to date;
              </li>
              <li>
                You will update any information recorded on the database, in the
                event of any changes to such information recorded so that the
                databases remain accurate, complete and up to date;
              </li>
              <li>
                Upon request you will supply us with any information that may be
                required by us in the event of an investigation by our officers
                on any listing made on the database or enquiry made on the
                database. Such information will include but not be limited to
                copies of a tenancy agreement, tenancy application forms,
                tenancy history ledger, tribunal notices and invoice for
                repairs;
              </li>
              <li>
                You understand that we will carry out random checks on the
                listing made on the database and enquiries made on the database.
                Furthermore, you will be required to provide us with the
                information required by our officers to prove the accuracy of
                the information recorded.
              </li>
              <li>
                You will be required to co-operate with other members who make
                enquiries about information contained within the database;
              </li>
              <li>
                You will be required to amend any record listed on the databases
                by a member that has been found to be inaccurate, incomplete and
                not up to date;
              </li>
              <li>
                You agree that we will not be used as a blackmailing tool;
              </li>
              <li>
                You will be required to notify us of the sale of a rent roll or
                business and advise our officer of the purchasers details.
                Furthermore, the member will provide any purchase of such
                business and or rent roll sufficient details on any tenants that
                the member may have listed on the database;
              </li>
              <li>
                When completing the listing on our database you will provide
                sufficient details so that member can easily identify the
                individual and the property listed for if such individual
                contacted the listing members thus making the listing accurate,
                complete, and up to date;
              </li>
              <li>
                You agree that prior to accessing information from our database
                you have provide the necessary notice as prescribed under the
                Residential Tenancies and Rooming Accommodation Act 2008 (QLD)
                section 458A;
              </li>
              <li>
                You agree that prior to listing in our database you have
                provided the necessary notice as prescribed under the
                Residential Tenancies and Rooming Accommodation Act 2008 (QLD)
                section 458B;
              </li>
            </ul>

            <h5>6. INTELLECTUAL PROPERTY</h5>
            <p>
              Our website and its content including our services, may represent
              or contain intellectual property owned by us, including
              trademarks, copyrights and proprietary information.
            </p>
            <p>
              You may not modify, publish, transmit, participate in the transfer
              or sale of, create derivative works from, distribute, display,
              reproduce or perform, or in any way exploit in any format
              whatsoever any of our intellectual property, in whole or in part,
              without our prior written consent.
            </p>
            <p>
              You retain the copyright an any other rights you already hold in
              Content which you uploaded for submission into our database. By
              uploading the content you give us a non-exclusive right to
              reproduce for its members to display and print any content which
              you submit into our database.
            </p>

            <h5>7. DISCLAIMERS</h5>
            <p>
              To the extent permitted by law, we have no liability or
              responsibility to you or any other person for any loss. You
              acknowledge that you access and use our website at your own risk.
            </p>
            <p>
              We make no representation or warranty that the website is
              appropriate or available for use in all countries or that the
              content satisfies the laws of all countries. You are responsible
              for ensuring that your access to and use of the website is not
              illegal or prohibited, and for your own compliance with applicable
              local laws.
            </p>
            <p>
              The website and our services are educational and informational
              resources only and are not a substitute for any kind of
              professional or specialist advice. We cannot guarantee the outcome
              of following any recommendations provided and any statements made
              regarding the potential outcomes are expressions of opinion only.
            </p>
            <p>
              It is imperative that all products are used only in the manner for
              which they are intended, and that care and common sense are
              exercised when using the products. To the extent permitted by law,
              Tenant Hub Pty Ltd does not accept any liability for, and is not
              responsible for, any damage, loss or injury caused by the use or
              misuse of our website.
            </p>
            <p>
              To the maximum extent permitted by law, you must indemnify us, and
              hold us harmless, against any Liability suffered or incurred by us
              arising from or in connection with your use of our website or any
              breach of these Terms or any applicable law by you. This indemnity
              is a continuing obligation, independent from the other obligations
              under these Terms, and continues after these Terms end. It is now
              necessary for us to suffer or incur any liability before enforcing
              a right of indemnity under these terms
            </p>
            <p>
              We are not liable to you or anyone else if interference with or
              damage to your computer systems occurs in connection with the use
              of this website or a linked website. You must take your own
              precautions to ensure that whatever you select from our website is
              free of viruses or anything else that may interfere with or damage
              the operations of your computer systems. Our website may contain
              links to websites operated by third parties. Such links are
              provided for convenience and may not remain current or be
              maintained. Unless expressly stated otherwise, we do not endorse
              and are not responsible for the content on third party linked
              websites and have no control over or rights in third party linked
              websites.
            </p>

            <h5>8. ACCEPTABLE USE POLICY</h5>
            <p>
              You must not use our website in any way that we deem to be
              inappropriate or unlawful, including but not limited to:
            </p>
            <ul style="list-style: none">
              <li>
                (a) any breach of privacy or any infringement upon the legal
                rights of any other person;
              </li>
              <li>
                (b) using our website to defame, harass, threaten, menace or
                offend any person;
              </li>
              <li>
                (c) uploading any virus, malware or other malicious software;
              </li>
              <li>
                (d) posting, commenting or otherwise transmitting any
                unauthorised material including, but not limited to any material
                that is or is likely to be defamatory, racist, obscene,
                threatening, pornographic or otherwise deemed by us to be
                unacceptable;
              </li>
              <li>(e) interfering with any user of our website;</li>
              <li>
                (f) using our website in a way which competes with our core
                business;
              </li>
              <li>
                (g) facilitating or assisting a third party to do any of the
                above.
              </li>
            </ul>

            <h5>9. SUSPENSION AND TERMINATION</h5>
            <p>
              Without prejudice to any other right or remedy available to us, if
              we consider that you have breached these terms or we otherwise
              consider it appropriate, we may immediately and without notice,
              suspend or terminate your membership and access to the website, or
              any part of it.
            </p>

            <h5>10. GENERAL</h5>
            <p>
              These terms, and any dispute relating to these terms or the
              website, are governed by and must be interpreted in accordance
              with the laws of Queensland. Each party submits to the
              non-exclusive jurisdiction of the courts of Queensland in relation
              to any dispute connected with these terms or the website.
            </p>
            <p>
              For us to waive a right under these terms, the waiver must be in
              writing. You must not assign or deal with the whole or any part of
              your rights or obligation under these Terms without our prior
              written consent.
            </p>
            <p>
              If any part or provision of these terms is or becomes illegal,
              unenforceable, or invalid, that part or provision is deemed to be
              modified to the extent required to remedy the illegality,
              unenforceability, or invalidity. If a modification is not
              possible, the part or provision must be treated for all purposes
              as severed from these terms. The remainder of these terms will be
              binding on you.
            </p>
            <p>
              In relation to a dispute, controversy or claim arising from, or in
              connection with, these Terms (including any question regarding its
              existence, validity or termination), a Party may not commence
              court proceedings relating to a Dispute without first meeting with
              a senior representative of the other Party to seek (in good faith)
              to resolve the Dispute. If the Parties cannot agree how to resolve
              the Dispute at the initial meeting, either Party may refer the
              matter to a mediator. If the parties cannot agree on who the
              mediator should be either Party may ask the Las Society of
              Queensland to appoint a mediator. The meditator will decide the
              time, place, and rules for mediation. The Parties agree to attend
              the mediation in good faith, to seek to resolve the Dispute. The
              Costs of the mediation will be shared equally between the Parties.
              Nothing in this clause will operate to prevent a Party from
              seeking urgent injunctive or equitable relief from a court or
              appropriate jurisdiction.
            </p>
            <p>
              You agree that we are able to send electronic mail to you and
              receive electronic mail from you. You release us from any
              Liability you may have as a result of any unauthorised copying,
              recording, reading or interference with that document or
              information after transmission, for any delay or non-delivery of
              any document or information and any document or information and
              for any damage caused to your system for any files by a transfer.
            </p>
            <p>
              Any notice given under these Terms must be in writing addressed to
              us at the address at the end of these Terms or to you at the
              address in your Account. Any notice may be sent by standard post
              or email, and will be deemed to have been served on the expiry of
              48 hours in the case of post, or at the time of transmission in
              the case of transmission by email.
            </p>
            <p>
              If a provision of these Terms is held to be void, invalid, illegal
              or unenforceable, that provision is to be read down as narrowly as
              necessary to allow it to be valid or enforceable, failing which,
              that provision (or that part of that provision) will be severed
              from these Terms without affecting the validity or enforceability
              of the remainder of that provision or the other provisions in
              these Terms.
            </p>

            <h5>11. QUESTION</h5>
            <p>
              If you have any question and is required to contact us please
              contact Tenant Hub Pty Ltd ACN 29 660 508 369 on Email:-
              “support@tenanthub.au”
            </p>
          </div>
          <!--end::Row-->
        </div>
        <!--end::Body-->
      </div>
      <!--end::Contact-->
    </div>
    <!--end::Content container-->

    <!-- <GoogleRecaptcha /> -->
  </div>
</template>
